<template>
  <div>
    <el-card>
      <el-form ref="form" label-width="220px" :model="form" class="stprForm">
        <el-form-item label="备料数量：" :rules="{ required: true }" class="vd_w85">
          <el-input :disabled="true" v-model="form.rfid_stpr_phr" placeholder="自动获取">
            <template slot="append">个</template>
          </el-input>
        </el-form-item>
        <el-form-item label="打样数量：" class="vd_w85">
          <el-input :disabled="true" v-model="form.smpl_num" placeholder="自动获取">
            <template slot="append">个</template>
          </el-input>
        </el-form-item>
        <div class="outer_box" v-if="stprList.length > 0">
          <div
            v-for="(item, index) in stprList"
            :key="index"
            class="stprMode"
            :class="smplMtrlIdContrast === item.smpl_mtrl_id ? 'stprModeCopy' : ''"
          >
            <el-row class="inner_row" :gutter="10">
              <el-col :md="9" class="inner_col">
                <el-row class="inner_row">
                  <el-col>
                    <span class="vd_font_20"> {{ item.mtrl_no }}</span>
                    <span class="vd_font_12 vd_inlBlo">
                      (

                      <div
                        v-if="item.colr_html"
                        :class="{ vd_div: item.colr_html, ilblock: true }"
                        :style="{ 'background-color': item.colr_html }"
                      ></div>
                      <span v-else>暂无色号</span>

                      {{ item.mtrl_color }}
                      )
                    </span>
                  </el-col>
                  <el-col>
                    <span class="vd_font_20">{{ item.mtrl_name }}</span>
                    <span class="vd_font_12 vd_inlBlo">(材质{{ item.mtrl_comp }})</span>
                  </el-col>
                  <template v-if="item.mtrl_type === 0">
                    <el-col>
                      <span v-if="item.qrcd_bin_list">{{ item.qrcd_bin_list }}</span
                      ><span v-else class="vd_err_color">暂无库位</span>
                      <span class="vd_font_12 vd_inlBlo">(门幅{{ helper.haveFour(item.mtrl_width) }}米)</span>
                    </el-col>
                    <el-col>
                      <div>
                        <span> {{ tottalw(index) }}</span>
                        <span class="vd_font_12 vd_inlBlo">cm(剪料总长)</span>
                      </div>
                      <div>
                        <span> {{ tottalh(index) }}</span>
                        <span class="vd_font_12 vd_inlBlo">cm(剪料总宽) </span>
                      </div>
                    </el-col>
                  </template>
                  <el-col>
                    <div>
                      <el-tag class="psBt" v-if="item.smpl_mtrl_status == 0" type="info">未出库</el-tag>
                      <el-tag class="psBt" v-else
                        >已出库<span class="vd_fw_600">{{ item.smpl_mtrl_status }}</span
                        >次
                      </el-tag>
                      <el-button class="psDBt" type="danger" size="mini" plain @click="deleteMtrlListById(item.smpl_mtrl_id)"
                        >删除
                      </el-button>
                    </div>
                  </el-col>
                </el-row>
              </el-col>
              <el-col :md="15" class="stprOverflow">
                <div v-if="item.mtrl_type === 0">
                  <div v-for="(item2, index2) in item.smpl_mtrl_len_wid.split(',')" :key="index2" :class="{ vd_mt_15: index2 != 0 }">
                    <template v-if="valueArr[index] && item2">
                      <el-row>
                        <el-col :span="11">
                          <el-input
                            v-model="valueArr[index][index2][0]"
                            @input="valInput1(index, index2)"
                            @change="valChange1(index, index2)"
                            @focus="inputFocus1(index, index2)"
                          >
                            <template slot="append">cm</template>
                          </el-input>
                        </el-col>
                        <el-col :span="2">
                          <div class="vd_center">*</div>
                        </el-col>
                        <el-col :span="11">
                          <el-input
                            v-model="valueArr[index][index2][1]"
                            @input="valInput2(index, index2)"
                            @change="valChange2(index, index2)"
                            @focus="inputFocus2(index, index2)"
                          >
                            <template slot="append">cm</template>
                          </el-input>
                        </el-col>
                      </el-row>
                    </template>

                    <template v-if="valueArr[index] && item2">
                      <el-row>
                        <el-col :span="11" class="vd_center_t">
                          <span class="vd_mt_16">总长</span>
                          {{ helper.retain1(valueArr[index][index2][0] * form.smpl_num, 2, 10000) }}
                        </el-col>
                        <el-col :span="2" class="vd_center_c">*</el-col>
                        <el-col :span="11" class="vd_center_t"><span class="vd_mt_16">宽</span> {{ valueArr[index][index2][1] }}</el-col>
                      </el-row>
                    </template>
                  </div>
                </div>
                <div v-else>
                  <el-row>
                    <el-col>
                      <div class="blockDIv">
                        <span> 耗料:</span>
                        <el-input
                          class="block"
                          v-model="item.smpl_mtrl_consume"
                          maxlength="10"
                          @input="item.smpl_mtrl_consume = helper.keepTNum1(item.smpl_mtrl_consume)"
                          @change="item.smpl_mtrl_consume = helper.retain1(item.smpl_mtrl_consume, 2, 10000000)"
                          show-word-limit
                          clearable
                          size="small"
                        >
                          <template slot="append">{{ item.mtrl_unit }}</template>
                        </el-input>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>

        <el-form-item align="center" label-width="0px">
          <el-button type="info" class="determine" @click="scancode" style="margin-right: 50px">扫码</el-button>
          <el-button type="success" class="determine" @click="submit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!--   提交 -->
    <el-dialog :visible.sync="stprDialogVisible" width="70%">
      <h2 class="diaTitle">
        提示：
        <div v-if="showFlag" style="color: #ef1808">还有物料未出库!!! 请谨慎提交</div>
        提交备料信息, 是否继续
      </h2>
      <div class="diaContent">
        <el-button @click="stprDialogVisible = false" class="cancel">取 消</el-button>
        <el-button type="primary" @click="stprDialog" class="determine">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 出库 -->
    <el-dialog title="提示出库" :visible.sync="scanDialogTableVisible" width="70%" :before-close="handleClose" v-loading="btnIsLoading">
      <el-input
        class="scanVal"
        v-model="scanVal"
        @keyup.enter.native="scankeyup"
        ref="smInput"
        clearable
        placeholder="请扫描二维码"
        :disabled="isDisabled"
      >
        <template slot="append">
          <el-link class="empty vg_pointer" @click="emptyClick"> 清空</el-link>
        </template>
      </el-input>
      <el-row v-if="addForm.length > 0" :gutter="30">
        <el-col :md="10">
          <div class="promptMode"><span>物料编号：</span>{{ showForm.mtrl_no }}</div>
          <div class="promptMode"><span>物料名称：</span>{{ showForm.mtrl_name }}</div>
          <div class="promptMode"><span>物料类型：</span>{{ formatType(showForm) }}</div>
          <div class="promptMode">
            <span>潘通色号：</span>
            <div
              v-if="showForm.colr_html"
              :class="{ vd_div: showForm.colr_html, ilblock: true }"
              :style="{ 'background-color': showForm.colr_html }"
            ></div>
            <span v-if="showForm.mtrl_color">{{ showForm.mtrl_color }}</span>
            <span v-else class="vd_fw_600">暂无潘通色号</span>
          </div>
          <div class="promptMode"><span>门幅：</span>{{ showForm.mtrl_width }}米</div>
          <button class="outbound" @click.prevent="scanTwoClick" :disabled="btnIsLoading">出库</button>
        </el-col>
        <el-col :md="14">
          <div class="promptMode"><span>库位：</span>{{ showForm.qrcd_bin_list ? showForm.qrcd_bin_list : showForm.qrcd_bin_no }}</div>
          <div class="promptMode">
            <span>出库次数：</span>
            <i class="vd_color_b vd_font_24 vd_font_n">{{ showForm.smpl_mtrl_status ? showForm.smpl_mtrl_status : 0 }}</i>
          </div>
          <div class="promptMode">
            <span>打样数量：</span>
            <i class="vd_color_g vd_font_24 vd_font_n">{{ form.smpl_num }}</i>
          </div>
          <template v-if="showForm.mtrl_type === 0">
            <div class="promptMode"><span>剪料总长：</span>{{ totalSH(showForm.smpl_mtrl_len_wid) }}厘米</div>
            <div class="promptMode"><span>剪料总宽：</span>{{ totalSW(showForm.smpl_mtrl_len_wid) }}厘米</div>
          </template>
          <template v-else>
            <div class="promptMode">
              <span>耗料:</span> {{ !showForm.mtrl_consume ? 0 : showForm.mtrl_consume }} {{ showForm.mtrl_unit }}
            </div>
          </template>
          <div v-if="showForm.mtrl_type === 0">
            <div class="widLen" style="display: flex" v-if="showForm.newLengthArr">
              <div style="display: flex; width: 200px; align-items: center">
                长
                <el-input-number :min="0" v-model="newHeight" />
              </div>
              <div style="display: flex; width: 200px; align-items: center; margin-left: 15px">
                宽
                <el-input-number :min="0" v-model="newWidth" />
              </div>
              <div style="display: flex; width: 100px; align-items: center; margin-left: 15px">
                <el-button type="primary" @click="addHWData">添加</el-button>
              </div>
            </div>
            <div class="widLen" v-for="(item, index) in showForm.smpl_mtrl_len_wid.split(',')" :key="index">
              长*宽{{ item }}
              <span>
                ({{ helper.retain1(item.split('*')[0] * form.smpl_num, 2, 10000) }} *
                {{ helper.retain1(item.split('*')[1], 2, 10000) }})厘米
              </span>
              <el-button type="danger" @click="deleteHWByIndex(index)" size="mini">删除</el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </el-dialog>

    <!-- <div class="loadingDiv" v-if="btnIsLoading">
      <div v-loading="btnIsLoading" class="loading"></div>
    </div> -->
  </div>
</template>

<script>
import { get, post, postNoCatch } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
import { mstfAPI } from '@api/modules/mstf';
import { qrcdAPI } from '@api/modules/qrcd';
import { cloneDeep } from 'lodash';

export default {
  data() {
    return {
      form: {
        rfid_stpr_phr: ''
      },
      stprDialogVisible: false,
      scanDialogTableVisible: false,
      stprList: [],
      scanVal: '',
      reqForm: {
        qrcd_id: '',
        mtrl_no: ''
      },
      showFlag: false,
      addForm: {},
      showForm: {},
      newWidth: 0,
      newHeight: 0,
      isDisabled: false,
      valueArr: [],
      btnIsLoading: false,
      formatType(row) {
        if (row.mtrl_type === 0) {
          return '原面料';
        } else if (row.mtrl_type === 1) {
          return '辅料';
        } else if (row.mtrl_type === 2) {
          return '包材';
        } else if (row.mtrl_type === 3) {
          return '加工面料';
        } else if (row.mtrl_type === 4) {
          return '裁片';
        } else if (row.mtrl_type === 5) {
          return '在制品';
        }
      },
      stagingVal1: '',
      stagingVal2: '',
      smplMtrlIdContrast: 0
    };
  },
  props: {
    prop: {
      type: Object
    }
  },
  mounted() {
    this.$EventBus.$on('stpr', () => {
      this.form.rfid_stpr_phr = '';
      this.stprList = [];
    });
    this.$EventBus.$on('stprEmit', (res, stprList) => {
      this.form.rfid_stpr_phr = res.rfid_stpr_num;
      this.form.smpl_num = res.smpl_num;
      let stprListA = stprList;
      for (let i = 0; i < stprListA.length; i++) {
        if (stprListA[i].smpl_mtrl_length) {
          stprListA[i].smpl_mtrl_length = this.helper.reservedDigits(stprListA[i].smpl_mtrl_length);
        }
        if (stprListA[i].smpl_mtrl_width) {
          stprListA[i].smpl_mtrl_width = this.helper.reservedDigits(stprListA[i].smpl_mtrl_width);
        }
      }
      this.stprList = [];
      this.stprList = stprListA;
      this.stprList.forEach((item, index) => {
        if (item.smpl_mtrl_len_wid) {
          let arr = item.smpl_mtrl_len_wid.split(',');
          this.valueArr[index] = [];
          arr.forEach((item2, index2) => {
            this.$set(this.valueArr[index], index2, []);
            this.valueArr[index][index2] = [];
            let arr2 = item2.split('*');
            this.$set(this.valueArr[index][index2], 0, this.helper.retain1(arr2[0], 2, 10000));
            this.valueArr[index][index2][1] = this.helper.retain1(arr2[1], 2, 10000);
          });
        }
      });
    });
  },
  computed: {
    tottalw() {
      return function (index) {
        let totalw = 0;
        let len_wid = this.stprList[index].smpl_mtrl_len_wid;
        if (len_wid) {
          len_wid = len_wid.split(',');
          len_wid.forEach((item, index) => {
            let arr = item.split('*');
            totalw = totalw + (arr[0] - 0);
          });
          return this.helper.retain1(totalw * this.form.smpl_num, 2, 100000);
        }
        return 0;
      };
    },
    tottalh() {
      return function (index) {
        let totalh = 0;
        let len_wid = this.stprList[index].smpl_mtrl_len_wid;
        if (len_wid) {
          len_wid = len_wid.split(',');
          len_wid.forEach((item, index) => {
            let arr = item.split('*');
            totalh = totalh + (arr[1] - 0);
          });
          return this.helper.retain1(totalh, 2, 100000);
        }
        return 0;
      };
    },
    totalSH() {
      return function (val) {
        if (!val) return 0;
        let total = 0;
        let arr = val.split(',');
        arr.forEach(item => {
          let newArr = item.split('*');
          total += newArr[0] - 0;
        });
        return this.helper.retain1(total * this.form.smpl_num, 2, 100000);
      };
    },
    totalSW() {
      return function (val) {
        if (!val) return 0;
        let total = 0;
        let arr = val.split(',');
        arr.forEach(item => {
          let newArr = item.split('*');
          total += newArr[1] - 0;
        });
        return this.helper.retain1(total, 2, 100000);
      };
    }
  },
  methods: {
    scankeyup() {
      this.isDisabled = true;
      get(qrcdAPI.getQrcdById, { qrcd_no: this.scanVal })
        .then(res => {
          if (res.data.code === 0) {
            this.addForm = {};
            this.reqForm.mtrl_no = res.data.data.form.mtrl_no;
            this.reqForm.qrcd_id = res.data.data.form.qrcd_id;
            this.reqForm.qrcd_bin_no = res.data.data.form.qrcd_bin_no;
            this.addForm = this.stprList.filter(item => {
              if (item.mtrl_no === res.data.data.form.mtrl_no) {
                return item;
              }
            });
            if (this.addForm.length === 0) {
              this.addForm[0] = res.data.data.form;
              this.showForm = res.data.data.form;
              this.showForm.smpl_mtrl_len_wid = '';
              this.showForm.newLengthArr = [];
              this.showForm.newLengthArr.push({ height: 0, width: 0 });
              return;
            }
            this.showForm.mtrl_no = this.addForm[0].mtrl_no;
            this.showForm.mtrl_name = this.addForm[0].mtrl_name;

            this.showForm.mtrl_type = this.addForm[0].mtrl_type;

            this.showForm.colr_html = this.addForm[0].colr_html;

            this.showForm.mtrl_width = this.addForm[0].mtrl_width;

            this.showForm.qrcd_bin_list = this.addForm[0].qrcd_bin_list;

            this.showForm.mtrl_color = this.addForm[0].mtrl_color;
            this.showForm.mtrl_consume = this.addForm[0].smpl_mtrl_consume;
            this.showForm.mtrl_unit = this.addForm[0].mtrl_unit;
            let arr = this.addForm[0].smpl_mtrl_len_wid;
            arr = arr.split(',');
            let str = arr.filter(item => {
              let newArr = item.split('*');
              if (newArr[0] == '' || newArr[1] == '') {
                return;
              } else {
                return item;
              }
            });

            str = str.join(',');
            (this.showForm.smpl_mtrl_len_wid = str), (this.showForm.smpl_mtrl_status = this.addForm[0].smpl_mtrl_status);
            return;
          }
          if (res.data.code == 1) {
            return this.$message.warning('二维码信息不正确');
          }
          if (res.data.code == 2) {
            this.addForm = {};
            this.showForm = {};
          }
          this.$message.warning(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    scancode() {
      this.newWidth = 0;
      this.newHeight = 0;
      this.scanDialogTableVisible = true;

      this.$nextTick(() => {
        this.$refs.smInput.focus();
      });
    },
    submit() {
      if (!this.prop.rfid_id) {
        return this.$message.error('请刷卡');
      }
      this.$refs['form'].validate(valid => {
        if (valid) {
          let flag = this.stprList.some(item => {
            return item.smpl_mtrl_status < 1;
          });
          if (flag) {
            this.showFlag = true;
          }
          this.stprDialogVisible = true;
        }
      });
    },
    stprDialog() {
      let form = {};
      form.rfid_id = this.prop.rfid_id;
      form.user_id = this.prop.user_id;
      form.stff_id = this.prop.stff_id;
      form.stff_name = this.prop.stff_name;
      form.user_rfid_no = this.prop.user_rfid_no;
      form.rfid_stpr_phr = this.form.rfid_stpr_phr;
      post(rfidAPI.edit_stpr, form)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }

          this.$emit('empty');
          this.form.rfid_stpr_phr = '';
          this.form.smpl_num = '';

          this.stprList = [];
          this.$message.success(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
      this.stprDialogVisible = false;
    },
    scanTwoClick() {
      let mstfBody = {};
      let arr = this.addForm[0].smpl_mtrl_len_wid;
      arr = arr.split(',');
      let str = arr.filter(item => {
        let newArr = item.split('*');
        if (newArr[0] == '' || newArr[1] == '') {
          return;
        } else {
          return item;
        }
      });
      str = str.join(',');
      if (this.showForm.mtrl_type === 0) {
        mstfBody.smpl_mtrl_len_wid = str;
      } else {
        mstfBody.smpl_mtrl_len_wid = 0;
      }
      mstfBody.smpl_mtrl_consume = this.addForm[0].smpl_mtrl_consume ?? 0;
      mstfBody.qrcd_id = this.reqForm.qrcd_id;
      mstfBody.mtrl_id = this.addForm[0].mtrl_id;
      mstfBody.mtrl_name = this.addForm[0].mtrl_name;
      mstfBody.mtrl_unit = this.addForm[0].mtrl_unit;
      mstfBody.mtrl_type = this.addForm[0].mtrl_type;
      mstfBody.mtrl_num = this.form.smpl_num;
      mstfBody.smpl_mtrl_id = this.addForm[0].smpl_mtrl_id;
      mstfBody.mtrl_no = this.addForm[0].mtrl_no;
      mstfBody.smpl_id = this.prop.smpl_id;

      this.btnIsLoading = true;
      post(mstfAPI.stpr_stock_out, mstfBody)
        .then(res => {
          if (res.data.code !== 0) {
            setTimeout(() => {
              this.btnIsLoading = false;
            }, 2000);
            return this.$message.error(res.data.msg);
          }
          setTimeout(() => {
            this.btnIsLoading = false;
            this.scanDialogTableVisible = false;
            this.$message.success(res.data.msg);
            this.scanVal = '';
            this.addForm = {};
            this.showForm = {};
            this.isDisabled = false;
            this.smplMtrlIdContrast = mstfBody.smpl_mtrl_id;
            this.newWidth = 0;
            this.newHeight = 0;
            this.$emit('outSuccess');
          }, 2000);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
          setTimeout(() => {
            this.btnIsLoading = false;
          }, 2000);
        });
    },
    handleClose() {
      this.scanVal = '';
      this.addForm = {};
      this.showForm = {};
      this.isDisabled = false;
      this.scanDialogTableVisible = false;
    },
    emptyClick() {
      this.isDisabled = false;
      this.addForm = {};
      this.showForm = {};
      this.scanVal = '';
      this.$nextTick(() => {
        this.$refs.smInput.focus();
      });
    },
    //输入框获取焦点 保存值
    inputFocus1(index1, index2) {
      this.stagingVal1 = this.valueArr[index1][index2][0];
    },
    inputFocus2(index1, index2) {
      this.stagingVal2 = this.valueArr[index1][index2][1];
    },
    valInput1(index1, index2) {
      if (this.valueArr[index1][index2][0].length <= 7) {
        this.valueArr[index1][index2][0] = this.helper.keepTNum1(this.valueArr[index1][index2][0]);
        this.$forceUpdate();
      } else {
        return;
      }
    },
    valInput2(index1, index2) {
      if (this.valueArr[index1][index2][1].length <= 7) {
        this.valueArr[index1][index2][1] = this.helper.keepTNum1(this.valueArr[index1][index2][1]);
        this.$forceUpdate();
      } else {
        return;
      }
    },
    valChange1(index1, index2) {
      let add = this.stprList[index1].smpl_mtrl_len_wid;
      add = add.split(',');
      add.forEach((item, i) => {
        add[i] = item.split('*');
      });
      add[index2][0] = this.helper.retain1(this.valueArr[index1][index2][0], 2, 10000);
      this.valueArr[index1][index2][0] = this.helper.retain1(this.valueArr[index1][index2][0], 2, 10000);
      if (this.valueArr[index1][index2][0] == '0.00') {
        this.valueArr[index1][index2][0] = this.helper.retain1(this.stagingVal1, 2, 10000);
        add[index2][0] = this.stagingVal1;
      }
      let str = '';
      add.forEach((item, index) => {
        str += item[0] + '*' + item[1] + ',';
      });
      str = str.substr(0, str.length - 1);
      this.stprList[index1].smpl_mtrl_len_wid = str;

      this.$forceUpdate();
    },
    valChange2(index1, index2) {
      let add = this.stprList[index1].smpl_mtrl_len_wid;
      add = add.split(',');
      add.forEach((item, i) => {
        add[i] = item.split('*');
      });
      add[index2][1] = this.helper.retain1(this.valueArr[index1][index2][1], 2, 10000);
      this.valueArr[index1][index2][1] = this.helper.retain1(this.valueArr[index1][index2][1], 2, 10000);
      if (this.valueArr[index1][index2][1] == '0.00') {
        this.valueArr[index1][index2][1] = this.helper.retain1(this.stagingVal2, 2, 10000);
        add[index2][1] = this.stagingVal2;
      }
      let str = '';
      add.forEach((item, index) => {
        str += item[0] + '*' + item[1] + ',';
      });
      str = str.substr(0, str.length - 1);
      this.stprList[index1].smpl_mtrl_len_wid = str;

      this.$forceUpdate();
    },
    addHWData() {
      let newHeight = this.helper.retain1(this.newHeight, 2, 10000);
      let newWidth = this.helper.retain1(this.newWidth, 2, 10000);
      this.showForm.smpl_mtrl_len_wid
        ? (this.showForm.smpl_mtrl_len_wid += `,${newHeight}*${newWidth}`)
        : (this.showForm.smpl_mtrl_len_wid += `${newHeight}*${newWidth}`);
      let temp = this.showForm.smpl_mtrl_len_wid;
      this.$set(this.showForm, 'smpl_mtrl_len_wid', temp);
      this.newHeight = 0;
      this.newWidth = 0;
    },
    deleteHWByIndex(index) {
      // 强制渲染
      let tempClone = cloneDeep(this.showForm);
      let split = tempClone.smpl_mtrl_len_wid.split(',');
      split.splice(index, 1);
      tempClone.smpl_mtrl_len_wid = split.join(',');
      this.showForm = tempClone;
      this.newHeight = Number(0);
      this.newWidth = Number(0);
    },
    /**
     * 根据id删除子表内容
     * @param smpl_mtrl_id
     */
    deleteMtrlListById(id) {
      rfidAPI.deleteMtrlListById(id).then(({ data, msg }) => {
        this.$message.success(msg);
        this.$emit('smplNoSearch');
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.stprForm .el-form-item {
  margin-bottom: 10px;
}

.stprForm {
  width: 100%;
  margin: 0 auto;
  // min-width: 500px;
}

::v-deep .stprForm .el-form-item__label {
  font-size: $font-max;
}

::v-deep .stprForm .el-input {
  height: 60px;
  font-size: $font-max;
}

::v-deep .stprForm .el-input input {
  height: 60px;
}

::v-deep .scanVal input {
  font-size: $font-max;
  height: 60px;
}

.stprTab {
  font-size: 18px;
  margin-bottom: 10px;
}

.stprTab .el-tag {
  font-size: 24px;
}

.outText {
  margin-top: 30px;
}

.outText .el-tag {
  width: 300px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  font-size: 38px;
}

.outbound {
  width: 300px;
  height: 120px;
  font-size: 36px;
  margin-top: 30px;
  background-color: #e5951e;
  border: 1px solid #e5951e;
  cursor: pointer;
}

.mateInfo {
  font-size: 28px;
}

.mtrl_width {
  font-size: 18px !important;
}

.mtrl_height {
  font-size: 18px !important;
}

.fontS {
  font-size: 22px;
}

::v-deep .el-form-item__error {
  font-size: 18px;
  margin-bottom: 2px;
}

.vd_div {
  width: 26px;
  height: 14px;
  border: none;
  margin-right: 5px;
  display: inline-block;
}

.ilblock {
  display: inline-block;
}

.vd_center {
  height: 60px;
  line-height: 60px;
  text-align: center;
  font-size: 28px;
}

.stprMode {
  width: 48%;
  font-size: 26px;
  margin-bottom: 10px;
  font-weight: 500;
  padding: 5px;
  // border: 1px solid #ccc;
  margin-left: 5px;
  box-shadow: 0px 0px 3px #888;
}

.stprModeCopy {
  box-shadow: 0px 0px 7px rgb(44, 202, 255);
}

.stprOverflow {
  overflow: auto;
  height: 295px;
}

.psBt {
  margin-top: 5px;
  font-size: 26px;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.psBt {
  margin-top: 5px;
  font-size: 26px;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.psDBt {
  margin-top: 5px;
  font-size: 26px;
  position: absolute;
  bottom: 10px;
  right: 0;
}

.vd_font_12 {
  font-size: 16px;
}

.outer_box {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 420px;
  overflow: auto;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px dashed #888;
}

.vd_mt_15 {
  margin-top: 15px;
}

.vd_fw_600 {
  font-weight: 600;
}

.vd_w85 {
  width: 85%;
}

::v-deep .outer_box .el-input-group__append {
  padding: 0;
  font-size: 15px;
}

.vd_err_color {
  color: red;
}

.inner_row {
  height: 100%;
}

.inner_col {
  height: 100%;
  position: relative;
}

.promptMode {
  font-weight: 600;
  font-size: 24px;

  .consume {
    font-weight: 600;
    font-size: 24px;
  }

  .vd_fw_600 {
    font-weight: 600;
  }

  span {
    font-size: 20px;
    font-weight: 400;
  }
}

.widLen {
  font-size: 20px;

  span {
    font-weight: 600;
  }
}

.vd_color_g {
  color: #448833;
}

.vd_color_b {
  color: #4848e3;
}

.vd_font_n {
  font-style: normal;
}

.vd_font_24 {
  font-size: 24px;
}

.vd_center_t {
  padding: 0 15px;
}

.vd_center_c {
  line-height: 35px;
  text-align: center;
}

.vd_font_20 {
  font-size: 20px !important;
  font-weight: 500;
  color: #303133;
}

.vd_inlBlo {
  display: inline-block;
}

.vd_mt_16 {
  font-size: 18px !important;
  font-weight: 600;
}

.blockDIv {
  display: flex;
  align-items: center;
}

.block {
  flex: 1;
}

.loadingDiv {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .loading {
    width: 70%;
    height: 70%;
    background-color: rgba(255, 255, 255, 0.2);
  }
}
</style>
