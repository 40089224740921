<template>
  <div>
    <el-card>
      <el-form label-width="220px" :model="form" ref="form" :rules="emcdRules" class="emcdForm">
        <el-row>
          <el-col :span="24">
            <el-form-item label="贴布片数：" prop="rfid_patch_num" class="rowc">
              <el-input
                v-model="form.rfid_patch_num"
                ref="emcdRef"
                maxlength="9"
                @keyup.13.native="emcdKeyup"
                show-word-limit
                @input="form.rfid_patch_num = keepNum99(form.rfid_patch_num)"
              >
                <template slot="append">片</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="rowc">
            <el-form-item label="制卡针数：" prop="rfid_emcd_phr">
              <el-input
                v-model="form.rfid_emcd_phr"
                ref="emcd_phrRef"
                maxlength="9"
                @keyup.enter.native="emcdPhrKeyup"
                show-word-limit
                @input="form.rfid_emcd_phr = helper.keepTNum(form.rfid_emcd_phr)"
              >
                <template slot="append">针</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="rowc">
            <el-form-item label="绣片数量：" prop="rfid_emcd_num">
              <el-input
                v-model="form.rfid_emcd_num"
                ref="emcd_numRef"
                maxlength="9"
                @keyup.enter.native="emcdNumKeyup"
                show-word-limit
                @input="form.rfid_emcd_num = keepNum99(form.rfid_emcd_num)"
              >
                <template slot="append">片</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="rowc">
            <el-form-item label="制卡个数：" prop="rfid_emcd_numb">
              <el-input
                v-model="form.rfid_emcd_numb"
                maxlength="9"
                show-word-limit
                ref="emcd_numb"
                @input="form.rfid_emcd_numb = helper.keepTNum(form.rfid_emcd_numb)"
                @keyup.enter.native="emcdNumbKeyup"
              >
                <template slot="append">个</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item class="ml_10" align="center">
          <el-button type="success" class="determine" @click="submit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!--   提交 -->
    <el-dialog :visible.sync="emcdDialogVisible" width="70%">
      <h2 class="diaTitle">提示：提交制卡信息, 是否继续</h2>
      <div class="diaContent">
        <el-button @click="emcdDialogVisible = false" class="cancel">取 消</el-button>
        <el-button type="primary" @click="emcdDialog" class="determine">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
import helper from '@assets/js/helper';
export default {
  data() {
    return {
      form: {
        rfid_emcd_phr: '',
        rfid_patch_num: '',
        rfid_emcd_num: '',
        rfid_emcd_numb: ''
      },
      emcdRules: {
        rfid_emcd_phr: [
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' },
          { required: true, message: '请填写制卡针数', trigger: true }
        ],
        rfid_patch_num: [
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' },
          { required: true, message: '请填写贴布片数', trigger: true }
        ],
        rfid_emcd_num: [
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' },
          { required: true, message: '请填写绣片数量', trigger: true }
        ],
        rfid_emcd_numb: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }]
      },
      emcdDialogVisible: false
    };
  },
  props: {
    prop: {
      type: Object
    }
  },
  methods: {
    submit() {
      if (!this.prop.rfid_id) {
        return this.$message.error('请刷卡');
      }
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.emcdDialogVisible = true;
        }
      });
    },
    emcdDialog() {
      this.emcdDialogVisible = false;
      let form = {};
      form.rfid_id = this.prop.rfid_id;
      form.user_id = this.prop.user_id;
      form.stff_id = this.prop.stff_id;
      form.stff_name = this.prop.stff_name;
      form.rfid_emcd_phr = this.form.rfid_emcd_phr;
      form.rfid_patch_num = this.form.rfid_patch_num;
      form.rfid_emcd_numb = this.form.rfid_emcd_numb;
      form.user_rfid_no = this.prop.user_rfid_no;
      form.rfid_emcd_num = this.form.rfid_emcd_num;
      post(rfidAPI.edit_emcd, form)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);
          this.$refs['form'].resetFields();
          this.$emit('empty');
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    emcdKeyup() {
      this.$refs.emcd_phrRef.focus();
      if (!this.form.rfid_patch_num) {
        this.form.rfid_patch_num = 0;
      }
    },
    emcdPhrKeyup() {
      this.$refs.emcd_numRef.focus();
      if (!this.form.rfid_emcd_phr) {
        this.form.rfid_emcd_phr = 0;
      }
    },
    emcdNumKeyup() {
      if (!this.form.rfid_emcd_num) {
        this.form.rfid_emcd_num = 0;
        this.$refs.emcd_numb.focus();
      }
    },
    emcdNumbKeyup() {
      if (!this.form.rfid_emcd_numb) {
        this.form.rfid_emcd_numb = 0;
      }
    },
    keepNum99(val){
      let keepTNum = Number(helper.keepTNum(val));
      if(keepTNum > 99){
        this.$message.warning("输入范围0～99超出最大限度");
        return 99;
      }else{
        return keepTNum;
      }
    }
  },
  mounted() {
    this.$EventBus.$on('emcd', () => {
      this.form.rfid_emcd_phr = '';
      this.form.rfid_patch_num = '';
      this.form.rfid_emcd_num = '';
      this.form.rfid_emcd_numb = '';
    });
    this.$EventBus.$on('emcdEmit', res => {
      this.form.rfid_emcd_phr = res.rfid_embr_time;
      this.form.rfid_patch_num = res.rfid_patch_num;
      this.form.rfid_emcd_num = res.rfid_emcd_num;
      this.form.rfid_emcd_numb = res.rfid_emcd_numb;
      if (!this.$refs.emcdRef) {
        return;
      } else {
        this.$refs.emcdRef.focus();
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.emcdForm .el-form-item {
  margin-bottom: 10px;
}
.emcdForm {
  width: 90%;
  margin: 0 auto;
  min-width: 500px;
}
::v-deep .emcdForm .el-form-item__label {
  font-size: $font-max;
}
::v-deep .emcdForm .el-input {
  font-size: $font-max;
  height: 60px;
}
::v-deep .el-form-item__content {
  margin-bottom: 20px;
}
::v-deep .emcdForm .el-input input {
  height: 60px;
}
::v-deep .el-form-item__error {
  font-size: 18px;
  margin-bottom: 2px;
}
</style>
