<template>
  <div>
    <el-card>
      <el-form label-width="220px" ref="manuRef" :rules="manuRules" :model="form" class="manuForm">
        <el-row class="rowc" :gutter="30">
          <el-form-item label="试样个数：">
            <el-col>
              <el-form-item prop="rfid_sample_indnum">
                <el-input
                  v-model="form.rfid_sample_indnum"
                  maxlength="11"
                  ref="indnumRef"
                  @keyup.enter.native="indnumkeyup"
                  show-word-limit
                  @input="form.rfid_sample_indnum = helper.keepTNum(form.rfid_sample_indnum)"
                >
                  <template slot="append" class="slot">个</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row class="rowc" :gutter="30">
          <el-form-item label="皮壳重量：" :rules="{ required: true }">
            <el-col>
              <el-form-item prop="smpl_skin_weight">
                <el-input
                  v-model="form.smpl_skin_weight"
                  ref="skinRef"
                  @keyup.enter.native="skinkeyup"
                  maxlength="11"
                  show-word-limit
                  @input="form.smpl_skin_weight = helper.keepTNum1(form.smpl_skin_weight)"
                >
                  <template slot="append" class="slot">克</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>

        <el-row class="rowc" :gutter="30">
          <el-form-item label="填充物重量：" :rules="{ required: true }">
            <el-col>
              <el-form-item prop="smpl_fill_weight">
                <el-input
                  v-model="form.smpl_fill_weight"
                  ref="fillRef"
                  @keyup.enter.native="fillkeyup"
                  maxlength="11"
                  show-word-limit
                  @input="form.smpl_fill_weight = helper.keepTNum1(form.smpl_fill_weight)"
                >
                  <template slot="append">克</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>
        <el-row class="rowc" :gutter="30">
          <el-form-item label="产品总重：" :rules="{ required: true }">
            <el-col>
              <el-form-item prop="smpl_weight">
                <el-input
                  v-model="form.smpl_weight"
                  ref="weightRef"
                  @keyup.enter.native="weightkeyup"
                  maxlength="11"
                  show-word-limit
                  @input="form.smpl_weight = helper.keepTNum1(form.smpl_weight)"
                >
                  <template slot="append">克</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>

        <el-row class="rowc" :gutter="30">
          <el-form-item label="剪线时间：">
            <el-col :span="12">
              <el-form-item
                prop="cutt_phr.points"
                :rules="
                  form.cutt_phr.points && form.cutt_phr.seconds
                    ? manuRules['cutt_phr.points']
                    : form.cutt_phr.seconds
                    ? [{ required: false }]
                    : manuRules['cutt_phr.points']
                "
              >
                <el-input
                  v-model="form.cutt_phr.points"
                  ref="cuttpRef"
                  @keyup.enter.native="cuttpKeyup"
                  @change="change"
                  maxlength="6"
                  show-word-limit
                  @input="form.cutt_phr.points = helper.keepTNum(form.cutt_phr.points)"
                >
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="cutt_phr.seconds"
                :rules="
                  form.cutt_phr.points && form.cutt_phr.seconds
                    ? manuRules['cutt_phr.seconds']
                    : form.cutt_phr.points
                    ? [{ required: false }]
                    : manuRules['cutt_phr.seconds']
                "
              >
                <el-input
                  v-model="form.cutt_phr.seconds"
                  ref="cuttsRef"
                  @keyup.enter.native="cuttsKeyup"
                  @change="change"
                  maxlength="2"
                  show-word-limit
                  @input="form.cutt_phr.seconds = helper.keepTNum(form.cutt_phr.seconds)"
                >
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>

        <el-row class="rowc" :gutter="30">
          <el-form-item label="翻皮时间：">
            <el-col :span="12">
              <el-form-item
                prop="skin_phr.points"
                :rules="
                  form.skin_phr.points && form.skin_phr.seconds
                    ? manuRules['skin_phr.points']
                    : form.skin_phr.seconds
                    ? [{ required: false }]
                    : manuRules['skin_phr.points']
                "
              >
                <el-input
                  v-model="form.skin_phr.points"
                  ref="skinpRef"
                  @keyup.enter.native="skinpKeyup"
                  @change="change"
                  maxlength="6"
                  show-word-limit
                  @input="form.skin_phr.points = helper.keepTNum(form.skin_phr.points)"
                >
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="skin_phr.seconds"
                :rules="
                  form.skin_phr.points && form.skin_phr.seconds
                    ? manuRules['skin_phr.seconds']
                    : form.skin_phr.points
                    ? [{ required: false }]
                    : manuRules['skin_phr.seconds']
                "
              >
                <el-input
                  v-model="form.skin_phr.seconds"
                  ref="skinsRef"
                  @keyup.enter.native="skinsKeyup"
                  @change="change"
                  maxlength="2"
                  show-word-limit
                  @input="form.skin_phr.seconds = helper.keepTNum(form.skin_phr.seconds)"
                >
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>

        <el-row class="rowc" :gutter="30">
          <el-form-item label="充棉时间：">
            <el-col :span="12">
              <el-form-item
                prop="cott_phr.points"
                :rules="
                  form.cott_phr.points && form.cott_phr.seconds
                    ? manuRules['cott_phr.points']
                    : form.cott_phr.seconds
                    ? [{ required: false }]
                    : manuRules['cott_phr.points']
                "
              >
                <el-input
                  v-model="form.cott_phr.points"
                  ref="cottpRef"
                  @keyup.enter.native="cottpKeyup"
                  @change="change"
                  maxlength="6"
                  show-word-limit
                  @input="form.cott_phr.points = helper.keepTNum(form.cott_phr.points)"
                >
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="cott_phr.seconds"
                :rules="
                  form.cott_phr.points && form.cott_phr.seconds
                    ? manuRules['cott_phr.seconds']
                    : form.cott_phr.points
                    ? [{ required: false }]
                    : manuRules['cott_phr.seconds']
                "
              >
                <el-input
                  v-model="form.cott_phr.seconds"
                  ref="cottsRef"
                  @keyup.enter.native="cottsKeyup"
                  @change="change"
                  maxlength="2"
                  show-word-limit
                  @input="form.cott_phr.seconds = helper.keepTNum(form.cott_phr.seconds)"
                >
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>

        <el-row class="rowc" :gutter="30">
          <el-form-item label="针工用时：">
            <el-col :span="12">
              <el-form-item
                prop="nedl_phr.points"
                :rules="
                  form.nedl_phr.points && form.nedl_phr.seconds
                    ? manuRules['nedl_phr.points']
                    : form.nedl_phr.seconds
                    ? [{ required: false }]
                    : manuRules['nedl_phr.points']
                "
              >
                <el-input
                  v-model="form.nedl_phr.points"
                  ref="nedlpRef"
                  @keyup.enter.native="nedlpKeyup"
                  @change="change"
                  maxlength="6"
                  show-word-limit
                  @input="form.nedl_phr.points = helper.keepTNum(form.nedl_phr.points)"
                >
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="nedl_phr.seconds"
                :rules="
                  form.nedl_phr.points && form.nedl_phr.seconds
                    ? manuRules['nedl_phr.seconds']
                    : form.nedl_phr.points
                    ? [{ required: false }]
                    : manuRules['nedl_phr.seconds']
                "
              >
                <el-input
                  v-model="form.nedl_phr.seconds"
                  ref="nedlsRef"
                  @keyup.enter.native="nedlsKeyup"
                  @change="change"
                  maxlength="2"
                  show-word-limit
                  @input="form.nedl_phr.seconds = helper.keepTNum(form.nedl_phr.seconds)"
                >
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>

        <el-row class="rowc" :gutter="30">
          <el-form-item label="整理时间：">
            <el-col :span="12">
              <el-form-item
                prop="sort_phr.points"
                :rules="
                  form.sort_phr.points && form.sort_phr.seconds
                    ? manuRules['sort_phr.points']
                    : form.sort_phr.seconds
                    ? [{ required: false }]
                    : manuRules['sort_phr.points']
                "
              >
                <el-input
                  v-model="form.sort_phr.points"
                  ref="sortpRef"
                  @keyup.enter.native="sortpKeyup"
                  @change="change"
                  maxlength="6"
                  show-word-limit
                  @input="form.sort_phr.points = helper.keepTNum(form.sort_phr.points)"
                >
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="sort_phr.seconds"
                :rules="
                  form.sort_phr.points && form.sort_phr.seconds
                    ? manuRules['sort_phr.seconds']
                    : form.sort_phr.points
                    ? [{ required: false }]
                    : manuRules['sort_phr.seconds']
                "
              >
                <el-input
                  v-model="form.sort_phr.seconds"
                  ref="sortsRef"
                  @keyup.enter.native="sortsKeyup"
                  @change="change"
                  maxlength="2"
                  show-word-limit
                  @input="form.sort_phr.seconds = helper.keepTNum(form.sort_phr.seconds)"
                >
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>

        <el-row class="rowc" :gutter="30">
          <el-form-item label="其他用时：">
            <el-col :span="12">
              <el-form-item
                prop="othe_phr.points"
                :rules="
                  form.othe_phr.points && form.othe_phr.seconds
                    ? manuRules['othe_phr.points']
                    : form.othe_phr.seconds
                    ? [{ required: false }]
                    : manuRules['othe_phr.points']
                "
              >
                <el-input
                  v-model="form.othe_phr.points"
                  ref="othepRef"
                  @keyup.enter.native="othepKeyup"
                  @change="change"
                  maxlength="6"
                  show-word-limit
                  @input="form.othe_phr.points = helper.keepTNum(form.othe_phr.points)"
                >
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="othe_phr.seconds"
                :rules="
                  form.othe_phr.points && form.othe_phr.seconds
                    ? manuRules['othe_phr.seconds']
                    : form.othe_phr.points
                    ? [{ required: false }]
                    : manuRules['othe_phr.seconds']
                "
              >
                <el-input
                  v-model="form.othe_phr.seconds"
                  ref="othesRef"
                  @keyup.enter.native="othesKeyup"
                  @change="change"
                  maxlength="2"
                  show-word-limit
                  @input="form.othe_phr.seconds = helper.keepTNum(form.othe_phr.seconds)"
                >
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>

        <el-row class="rowc" :gutter="30">
          <el-form-item label="包装用时：">
            <el-col :span="12">
              <el-form-item
                prop="pack_phr.points"
                :rules="
                  form.pack_phr.points && form.pack_phr.seconds
                    ? manuRules['pack_phr.points']
                    : form.pack_phr.seconds
                    ? [{ required: false }]
                    : manuRules['pack_phr.points']
                "
              >
                <el-input
                  v-model="form.pack_phr.points"
                  ref="packpRef"
                  @keyup.enter.native="packpKeyup"
                  @change="change"
                  maxlength="6"
                  show-word-limit
                  @input="form.pack_phr.points = helper.keepTNum(form.pack_phr.points)"
                >
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item
                prop="pack_phr.seconds"
                :rules="
                  form.pack_phr.points && form.pack_phr.seconds
                    ? manuRules['pack_phr.seconds']
                    : form.pack_phr.points
                    ? [{ required: false }]
                    : manuRules['pack_phr.seconds']
                "
              >
                <el-input
                  v-model="form.pack_phr.seconds"
                  ref="packsRef"
                  @keyup.enter.native="packsKeyup"
                  @change="change"
                  maxlength="2"
                  show-word-limit
                  @input="form.pack_phr.seconds = helper.keepTNum(form.pack_phr.seconds)"
                >
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>

        <el-row class="rowc" :gutter="30">
          <el-form-item label="手工总用时：">
            <el-col :span="12">
              <el-form-item prop="rfid_manu_phr.points">
                <!--   :rules="
                  form.rfid_manu_phr.points && form.rfid_manu_phr.seconds
                    ? manuRules['rfid_manu_phr.points']
                    : form.rfid_manu_phr.seconds
                    ? [{ required: false }]
                    : manuRules['rfid_manu_phr.points']
                " -->
                <el-input
                  :disabled="true"
                  v-model="form.rfid_manu_phr.points"
                  ref="manupRef"
                  @keyup.enter.native="manupKeyup"
                  maxlength="6"
                  show-word-limit
                  @input="form.rfid_manu_phr.points = helper.keepTNum(form.rfid_manu_phr.points)"
                >
                  <template slot="append">分钟</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="rfid_manu_phr.seconds">
                <!--  :rules="
                  form.rfid_manu_phr.points && form.rfid_manu_phr.seconds
                    ? manuRules['rfid_manu_phr.seconds']
                    : form.rfid_manu_phr.points
                    ? [{ required: false }]
                    : manuRules['rfid_manu_phr.seconds']
                " -->

                <el-input
                  :disabled="true"
                  v-model="form.rfid_manu_phr.seconds"
                  ref="manusRef"
                  @keyup.enter.native="manusKeyup"
                  maxlength="2"
                  show-word-limit
                  @input="form.rfid_manu_phr.seconds = helper.keepTNum(form.rfid_manu_phr.seconds)"
                >
                  <template slot="append">秒</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>

        <el-row class="rowc" :gutter="30">
          <el-form-item prop="remark" label="备注：">
            <el-col :span="24">
              <el-form-item>
                <el-input
                  class="textarea"
                  type="textarea"
                  v-model="form.remark"
                  ref="remarkRef"
                  maxlength="200"
                  show-word-limit
                  :autosize="{ minRows: 6, maxRows: 8 }"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-form-item>
        </el-row>

        <el-form-item class="rowc ml_10" align="center">
          <el-button class="determine" type="success" @click="submit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <!--   提交 -->
    <el-dialog :visible.sync="manuDialogVisible" width="70%">
      <h2 class="diaTitle">提示：提交手工信息, 是否继续</h2>
      <div class="diaContent">
        <el-button @click="manuDialogVisible = false" class="cancel">取 消</el-button>
        <el-button type="primary" @click="manuDialog" class="determine">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
import helper from '@assets/js/helper';
export default {
  computed: {
    helper() {
      return helper
    }
  },
  data() {
    return {
      manuDialogVisible: false,
      form: {
        rfid_manu_phr: {
          points: '',
          seconds: ''
        }, //总用时
        nedl_phr: {
          points: '',
          seconds: ''
        }, //针工用时
        skin_phr: {
          points: '',
          seconds: ''
        }, //翻皮壳用时
        cutt_phr: {
          points: '',
          seconds: ''
        }, //减线用时
        cott_phr: {
          points: '',
          seconds: ''
        }, //充棉用时
        sort_phr: {
          points: '',
          seconds: ''
        }, //整理用时
        othe_phr: {
          points: '',
          seconds: ''
        }, //其他用时
        pack_phr: {
          points: '2',
          seconds: ''
        }, //包装用时

        remark: '', //备注 1
        smpl_weight: '', //产品总重量 单位克1
        smpl_skin_weight: '', //皮壳重量 1
        smpl_fill_weight: '', //填充物重量 1
        rfid_sample_indnum: '' //试样个数
      },
      manuRules: {
        smpl_skin_weight: [
          { required: true, message: '请输入皮壳重量', trigger: 'blur' },
          {
            pattern: /^\d{0,6}(\.\d{1,4})?$/,
            message: '最大只能输入999999.9999且最多4位小数!'
          }
        ],
        smpl_fill_weight: [
          { required: true, message: '请输入填充物重量', trigger: 'blur' },
          {
            pattern: /^\d{0,6}(\.\d{1,4})?$/,
            message: '最大只能输入999999.9999且最多4位小数!'
          }
        ],
        smpl_weight: [
          { required: true, message: '请输入产品总重', trigger: 'blur' },
          {
            pattern: /^\d{0,6}(\.\d{1,4})?$/,
            message: '最大只能输入999999.9999且最多4位小数!'
          }
        ],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        'skin_phr.points': [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        'skin_phr.seconds': [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],

        'cutt_phr.points': [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        'cutt_phr.seconds': [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],

        'nedl_phr.points': [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        'nedl_phr.seconds': [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],

        'sort_phr.points': [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        'sort_phr.seconds': [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],

        'cott_phr.points': [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        'cott_phr.seconds': [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],

        'othe_phr.points': [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        'othe_phr.seconds': [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],

        'pack_phr.points': [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        'pack_phr.seconds': [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ],

        'rfid_manu_phr.points': [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }],
        'rfid_manu_phr.seconds': [
          {
            pattern: /^([1-5][0-9]|[0-9])$/,
            message: '请输入0-59之间的正整数!'
          }
        ]
      }
    };
  },
  props: {
    prop: {
      type: Object
    }
  },
  methods: {
    submit() {
      if (!this.prop.rfid_id) {
        return this.$message.error('请刷卡');
      }
      this.$refs['manuRef'].validate(valid => {
        if (valid) {
          this.manuDialogVisible = true;
        }
      });
    },
    manuDialog() {
      let form = {};
      form.rfid_id = this.prop.rfid_id;
      form.user_id = this.prop.user_id;
      form.stff_id = this.prop.stff_id;
      form.stff_name = this.prop.stff_name;
      form.user_rfid_no = this.prop.user_rfid_no;

      form.skin_phr = this.form.skin_phr.points * 60 - 0 + (this.form.skin_phr.seconds - 0); //翻皮壳时间

      form.nedl_phr = this.form.nedl_phr.points * 60 - 0 + (this.form.nedl_phr.seconds - 0); //针工用时时间

      form.cutt_phr = this.form.cutt_phr.points * 60 - 0 + (this.form.cutt_phr.seconds - 0); //减线时间

      form.cott_phr = this.form.cott_phr.points * 60 - 0 + (this.form.cott_phr.seconds - 0); //充棉时间

      form.sort_phr = this.form.sort_phr.points * 60 - 0 + (this.form.sort_phr.seconds - 0); //整理时间

      form.othe_phr = this.form.othe_phr.points * 60 - 0 + (this.form.othe_phr.seconds - 0); //其他时间
      form.pack_phr = this.form.pack_phr.points * 60 - 0 + (this.form.pack_phr.seconds - 0); //包装时间
      form.rfid_manu_phr = this.form.rfid_manu_phr.points * 60 + (this.form.rfid_manu_phr.seconds - 0);

      form.remark = this.form.remark; //备注
      form.smpl_weight = this.form.smpl_weight; //产品总重量
      form.smpl_skin_weight = this.form.smpl_skin_weight; //smpl_skin_weight
      form.smpl_fill_weight = this.form.smpl_fill_weight; //填充物重量
      form.rfid_sample_indnum = this.form.rfid_sample_indnum; //试样个数

      post(rfidAPI.edit_manu, form)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);
          this.$refs.manuRef.resetFields();
          this.$emit('empty');
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
      this.manuDialogVisible = false;
    },
    skinkeyup() {
      this.$refs.fillRef.focus();
    },
    fillkeyup() {
      this.$refs.weightRef.focus();
    },
    weightkeyup() {
      this.$refs.cuttpRef.focus();
    },
    indnumkeyup(){
      this.$refs.skinRef.focus();
    },
    cuttpKeyup() {
      if (!this.form.cutt_phr.points) {
        this.form.cutt_phr.points = 0;
      }
      this.$refs.cuttsRef.focus();
    },
    cuttsKeyup() {
      if (this.form.cutt_phr.seconds == '') {
        this.form.cutt_phr.seconds = 0;
      }
      this.$refs.skinpRef.focus();
    },

    skinpKeyup() {
      if (this.form.skin_phr.points == '') {
        this.form.skin_phr.points = 0;
      }
      this.$refs.skinsRef.focus();
    },
    skinsKeyup() {
      if (this.form.skin_phr.seconds == '') {
        this.form.skin_phr.seconds = 0;
      }
      this.$refs.cottpRef.focus();
    },

    cottsKeyup() {
      if (this.form.cott_phr.seconds == '') {
        this.form.cott_phr.seconds = 0;
      }
      this.$refs.nedlpRef.focus();
    },
    cottpKeyup() {
      if (this.form.cott_phr.points == '') {
        this.form.cott_phr.points = 0;
      }
      this.$refs.cottsRef.focus();
    },

    othepKeyup() {
      if (this.form.othe_phr.points == '') {
        this.form.othe_phr.points = 0;
      }
      this.$refs.othesRef.focus();
    },

    nedlpKeyup() {
      if (this.form.nedl_phr.points == '') {
        this.form.nedl_phr.points = 0;
      }
      this.$refs.nedlsRef.focus();
    },
    nedlsKeyup() {
      if (this.form.nedl_phr.seconds == '') {
        this.form.nedl_phr.seconds = 0;
      }
      this.$refs.sortpRef.focus();
    },
    sortpKeyup() {
      if (this.form.sort_phr.points == '') {
        this.form.sort_phr.points = 0;
      }
      this.$refs.sortsRef.focus();
    },
    sortsKeyup() {
      if (this.form.sort_phr.seconds == '') {
        this.form.sort_phr.seconds = 0;
      }
      this.$refs.othepRef.focus();
    },

    othesKeyup() {
      if (this.form.othe_phr.seconds == '') {
        this.form.othe_phr.seconds = 0;
      }
      this.$refs.packpRef.focus();
    },
    packpKeyup() {
      if (this.form.pack_phr.points == '') {
        this.form.pack_phr.points = 0;
      }
      this.$refs.packsRef.focus();
    },
    packsKeyup() {
      if (this.form.pack_phr.seconds == '') {
        this.form.pack_phr.seconds = 0;
      }
      this.$refs.manupRef.focus();
    },
    manupKeyup() {
      this.$refs.manusRef.focus();
    },
    manusKeyup() {
      this.$refs.remarkRef.focus();
    },
    sett() {
      let form = {};
      form.skin_phr = this.form.skin_phr.points * 60 - 0 + (this.form.skin_phr.seconds - 0); //翻皮壳时间

      form.nedl_phr = this.form.nedl_phr.points * 60 - 0 + (this.form.nedl_phr.seconds - 0); //针工用时时间

      form.cutt_phr = this.form.cutt_phr.points * 60 - 0 + (this.form.cutt_phr.seconds - 0); //减线时间

      form.cott_phr = this.form.cott_phr.points * 60 - 0 + (this.form.cott_phr.seconds - 0); //充棉时间

      form.sort_phr = this.form.sort_phr.points * 60 - 0 + (this.form.sort_phr.seconds - 0); //整理时间

      form.othe_phr = this.form.othe_phr.points * 60 - 0 + (this.form.othe_phr.seconds - 0); //其他时间
      form.pack_phr = this.form.pack_phr.points * 60 - 0 + (this.form.pack_phr.seconds - 0); //包装时间
      form.rfid_manu_phr = form.nedl_phr + form.cutt_phr + form.cott_phr + form.sort_phr + form.othe_phr + form.pack_phr + form.skin_phr;
      if (isNaN(form.rfid_manu_phr)) {
        this.form.rfid_manu_phr.points = '';
        this.form.rfid_manu_phr.seconds = '';
      } else {
        this.form.rfid_manu_phr.points = parseInt(form.rfid_manu_phr / 60) == 0 ? '' : parseInt(form.rfid_manu_phr / 60);
        this.form.rfid_manu_phr.seconds = form.rfid_manu_phr % 60 == 0 ? '' : form.rfid_manu_phr % 60;
      }
    },
    change() {
      this.sett();
    }
  },
  mounted() {
    this.$EventBus.$on('manu', () => {
      if (!this.$refs['manuRef']) {
        return;
      } else {
        this.$refs['manuRef'].resetFields();
      }
    });
    this.$EventBus.$on('manuEmit', res => {
      this.form.smpl_weight = res.smpl_weight;
      this.form.smpl_skin_weight = res.smpl_skin_weight;
      this.form.smpl_fill_weight = res.smpl_fill_weight;
      this.form.rfid_sample_indnum = res.rfid_sample_indnum;
      this.form.remark = res.remark;

      if (res.rfid_manu_phr !== null) {
        this.form.rfid_manu_phr.points = parseInt(res.rfid_manu_phr / 60);
        this.form.rfid_manu_phr.seconds = res.rfid_manu_phr % 60; //总时间
      }
      if (res.nedl_phr !== null) {
        this.form.nedl_phr.points = parseInt(res.nedl_phr / 60);
        this.form.nedl_phr.seconds = res.nedl_phr % 60; //针工时间
      }
      if (res.skin_phr !== null) {
        this.form.skin_phr.points = parseInt(res.skin_phr / 60);
        this.form.skin_phr.seconds = res.skin_phr % 60; //翻皮壳时间
      }
      if (res.cutt_phr !== null) {
        this.form.cutt_phr.points = parseInt(res.cutt_phr / 60);
        this.form.cutt_phr.seconds = res.cutt_phr % 60; //减线时间
      }
      if (res.cott_phr !== null) {
        this.form.cott_phr.points = parseInt(res.cott_phr / 60);
        this.form.cott_phr.seconds = res.cott_phr % 60; //充棉时间
      }
      if (res.sort_phr !== null) {
        this.form.sort_phr.points = parseInt(res.sort_phr / 60);
        this.form.sort_phr.seconds = res.sort_phr % 60; //整理时间
      }
      if (res.othe_phr !== null) {
        this.form.othe_phr.points = parseInt(res.othe_phr / 60);
        this.form.othe_phr.seconds = res.othe_phr % 60; //其他时间
      }
      if (res.pack_phr !== null) {
        this.form.pack_phr.points = parseInt(res.pack_phr / 60);
        this.form.pack_phr.seconds = res.pack_phr % 60; //包装时间
      }
      if (!this.$refs.skinRef) {
        return;
      } else {
        this.$refs.skinRef.focus();
      }
    });
  }
};
</script>

<style lang="scss" scoped>
::v-deep .manuForm {
  width: 90%;
  margin: 0 auto;
  min-width: 500px;
}
.textarea {
  min-width: 500px;
  font-size: $font-max;
  font-weight: 700;
}
.rowc {
  margin-top: 20px;
}
::v-deep .manuForm .el-form-item__label {
  font-size: $font-max;
}
::v-deep .manuForm .el-input {
  height: 60px;
  font-size: $font-max;
}
::v-deep .manuForm .el-input input {
  height: 60px;
}
::v-deep .el-form-item__error {
  font-size: 18px;
  margin-bottom: 2px;
}
::v-deep .el-form-item__content {
  margin-bottom: 2px;
}
</style>
