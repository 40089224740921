import topic from '../topic';
let prodUrl = '';
if (process.env.NODE_ENV === 'production') {
  prodUrl = '/dyj';
}
export const docuAPI = {
  getDocus: topic.docuTopic + '/get_docus',
  uploadDocuments: prodUrl + topic.docuTopic + '/upload_documents',
  destroyDocuPterById: topic.docuTopic + '/destroy_docu_pter_by_id',
  upload_video: prodUrl + topic.docuTopic + '/upload_video',
  destroyDocuPtersByIds: topic.docuTopic + '/destroy_docu_pters_by_ids'
};
