<template>
  <div>
    <el-card>
      <el-form label-width="120px" class="inspecForm" ref="inspRef" :rules="inspRules" :model="form">
        <el-row>
          <el-col :span="24">
            <el-form-item label="备注：" prop="remark">
              <el-input
                class="insptext textarea"
                v-model="form.remark"
                type="textarea"
                maxlength="200"
                show-word-limit
                ref="remark"
                :autosize="{ minRows: 6, maxRows: 8 }"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col align="center">
            <el-button @click="submit" type="success" class="determine" style="margin-right: 50px">通过</el-button>
            <el-button @click="reject" class="btn_reject" type="danger">拒绝</el-button>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <!--   通过 -->
    <el-dialog :visible.sync="reDialogVisible" width="70%">
      <h2 class="diaTitle">提示：检验信息通过, 是否继续</h2>
      <div class="diaContent">
        <el-button @click="reDialogVisible = false" class="cancel">取 消</el-button>
        <el-button type="primary" @click="thrDialog" class="determine">确 定</el-button>
      </div>
    </el-dialog>
    <!--   拒绝 -->
    <el-dialog :visible.sync="canDialogVisible" width="70%">
      <h2 class="diaTitle">提示：检验信息拒绝, 是否继续</h2>
      <div class="diaContent">
        <el-button @click="canDialogVisible = false" class="cancel">取 消</el-button>
        <el-button type="primary" @click="rejDialog" class="determine">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 审批 -->
    <el-dialog :visible.sync="canDialogVisible1" title="快捷审批" @closed="rejDialogCentel" width="50%">
      <div style="font-size: 30px">
        <div class="vd_dis_cen">
          <span>选择审批人：</span>
          <el-checkbox-group v-model="checkStffIds">
            <el-checkbox v-for="item in checkList" :label="item.user_id" :key="item.user_id"
              >{{ item.stff_name }}({{ item.dept_name }})</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <div class="vd_dis_cen vg_mt_16">
          <span style="width: 150px">备注:</span>
          <el-input type="textarea" :rows="2" placeholder="请输入备注" v-model="textarea"></el-input>
        </div>
      </div>
      <div class="vg_mt_16 vd_dis_cen">
        <el-button @click="rejDialogCentel" class="cancel">取 消</el-button>
        <el-button type="primary" @click="rejDialog1" class="cancel">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
import { apprAPI } from '@api/modules/appr';
export default {
  data() {
    return {
      form: {
        remark: ''
      },
      reDialogVisible: false,
      canDialogVisible: false,
      canDialogVisible1: false,
      inspRules: {
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }]
      },
      checkList: [],
      checkStffIds: [],
      apprFormB: null,
      textarea: ''
    };
  },

  props: {
    prop: {
      type: Object
    },
    SmplId: {
      type: Number
    }
  },
  created() {
    // this.getStffList()
  },
  methods: {
    // 获取所有信息
    getApprObj() {
      get(apprAPI.getApprInsts, { perm_id: 112, form_id: this.SmplId })
        .then(res => {
          if (res.data.code === 0) {
            this.apprForm = res.data.data.next;
            this.apprForm.appr_inst_action = 1;
            this.getStffList();
          } else {
            let mg = res.data.msg;
            let err = 'error';
            this.$message({ type: err, message: mg });
          }
        })
        .catch(() => {});
    },
    // 获取审批人
    getStffList() {
      const nodeBody = {};
      nodeBody.perm_id = 112;
      // nodeBody.form_id = 1452
      nodeBody.form_id = this.apprForm.form_id;
      nodeBody.appr_inst_this_node = this.apprForm.appr_inst_this_node;
      nodeBody.appr_inst_action = this.apprForm.appr_inst_action;
      get(apprAPI.getApprInstNextNode, nodeBody)
        .then(res => {
          if (res.data.code === 0) {
            this.apprFormB = res.data.data;
            this.checkList = this.apprFormB.appr_node_user_list;
            if (sessionStorage.getItem('checkStffIds')) {
              let temp = [];
              let temp1 = sessionStorage.getItem('checkStffIds').split(',');
              temp1.forEach(item => {
                checkList.forEach(item1 => {
                  if (item.user_id === item1.user_id) {
                    temp.push(item.user_id);
                  }
                });
              });
              this.checkStffIds = temp;
            }
            this.$emit('empty');
            setTimeout(() => {
              this.canDialogVisible1 = true;
            }, 300);
          } else {
            let mg = res.data.msg;
            this.$message.error(mg);
          }
        })
        .catch(() => {});
    },
    submit() {
      if (this.form.isImage === 0) {
        this.$alert('样品工单中图片未插入,不可点击通过!', '警告!', {
          confirmButtonText: '确定'
        });
        return;
      }
      if (this.form.isOutMtrl === 0) {
        this.$alert('有未出库的物料,不可点击通过!', '警告!', {
          confirmButtonText: '确定'
        });
        return;
      }
      this.$refs['inspRef'].validate(valid => {
        if (valid) {
          this.reDialogVisible = true;
        }
      });
    },
    reject() {
      if (!this.prop.rfid_id) {
        return this.$message.error('请刷卡');
      }

      this.$refs['inspRef'].validate(valid => {
        if (valid) {
          this.canDialogVisible = true;
        }
      });
    },
    //通过
    thrDialog() {
      this.reDialogVisible = false;
      let form = {};
      form.rfid_id = this.prop.rfid_id;
      form.user_id = this.prop.user_id;
      form.stff_id = this.prop.stff_id;
      form.user_rfid_no = this.prop.user_rfid_no;
      form.stff_name = this.prop.stff_name;
      form.rfid_insp_status = 1;
      form.remark = this.form.remark;
      post(rfidAPI.edit_insp, form)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.form.remark = '';
          this.$message.success(res.data.msg);
          // this.$emit("empty");
          this.getApprObj();
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    },
    // 审批取消
    rejDialogCentel() {
      this.canDialogVisible1 = false;
      this.$emit('empty');
    },
    // 审批确定
    rejDialog1() {
      this.apprForm.appr_inst_remark = this.textarea;
      this.apprForm.next_user_id_list = this.checkStffIds;
      this.apprForm.appr_inst_next_node = this.apprFormB.appr_node_id;
      post(apprAPI.Approve, this.apprForm)
        .then(res => {
          if (res.data.code === 0) {
            this.$message.success('成功');
            this.checkStffIds = [];
            sessionStorage.setItem('checkStffIds', this.checkStffIds.join(','));
            this.$emit('empty');
            setTimeout(() => {
              this.canDialogVisible1 = false;
            }, 300);
          } else {
            let msg = res.data.msg;
            let q = 'error';
            this.$message({ type: q, message: msg });
          }
        })
        .catch(() => {});
    },
    //拒绝
    rejDialog() {
      this.canDialogVisible = false;
      this.form.remark = '';
      let form = {};
      form.rfid_id = this.prop.rfid_id;
      form.user_id = this.prop.user_id;
      form.stff_id = this.prop.stff_id;
      form.stff_name = this.prop.stff_name;
      form.user_rfid_no = this.prop.user_rfid_no;
      form.rfid_insp_status = 0;
      post(rfidAPI.edit_insp, form)
        .then(res => {
          if (res.data.code !== 0) {
            return console.log('提交失败');
          }
          this.$message.success(res.data.msg);
          this.$emit('empty');
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  },
  mounted() {
    this.$EventBus.$on('insp', () => {
      this.form.remark = '';
    });
    this.$EventBus.$on('inspEmit', res => {
      this.form.isOutMtrl = res.isOutMtrl;
      this.form.isImage = res.isImage;
    });
  }
};
</script>

<style lang="scss" scoped>
.inspecForm {
  width: 90%;
  margin: 0 auto;
  min-width: 500px;
}
::v-deep .inspecForm .el-form-item__label {
  font-size: $font-max;
}

.insptext {
  margin-bottom: 10px;
  min-width: 500px;
  font-size: $font-max;
}
::v-deep .el-form-item__error {
  font-size: 18px;
  margin-bottom: 2px;
}
.vd_dis_cen {
  display: flex;
}
</style>
