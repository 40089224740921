<template>
  <div>
    <el-card>
      <el-form ref="form" :model="form" label-width="240px" :rules="laseRules" class="laseForm">
        <el-form-item label="产品激光用量：" prop="rfid_lase_phr">
          <el-input
            v-model="form.rfid_lase_phr"
            ref="laseRef"
            maxlength="9"
            @keyup.enter.native="lasePhrKeyup"
            show-word-limit
            @input="form.rfid_lase_phr = keepNum99(form.rfid_lase_phr)"
          >
            <template slot="append">片</template>
          </el-input>
        </el-form-item>
        <el-form-item label="贴布激光用量：" prop="rfid_patch_lase">
          <el-input
            v-model="form.rfid_patch_lase"
            ref="lasepatchRef"
            maxlength="9"
            show-word-limit
            @input="form.rfid_patch_lase = keepNum99(form.rfid_patch_lase)"
          >
            <template slot="append">片</template>
          </el-input>
        </el-form-item>
        <el-form-item class="ml_10" align="center">
          <el-button type="success" @click="submit" class="determine">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <!--   提交 -->
    <el-dialog :visible.sync="laseDialogVisible" width="70%">
      <h2 class="diaTitle">提示：提交激光信息, 是否继续</h2>
      <div class="diaContent">
        <el-button @click="laseDialogVisible = false" class="cancel">取 消</el-button>
        <el-button type="primary" @click="laseDialog" class="determine">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { rfidAPI } from '@api/modules/rfid';
import helper from '@assets/js/helper';
export default {
  data() {
    return {
      form: {
        rfid_lase_phr: '',
        rfid_patch_lase: ''
      },
      laseRules: {
        rfid_lase_phr: [
          { pattern: /^[0-9]*$/, message: '只能输入零和正整数!' },
          { required: true, message: '请输入激光用量', trigger: 'blur' }
        ],
        rfid_patch_lase: [{ pattern: /^[0-9]*$/, message: '只能输入零和正整数!' }]
      },
      laseDialogVisible: false
    };
  },
  props: {
    prop: {
      type: Object
    }
  },
  methods: {
    submit() {
      if (!this.prop.rfid_id) {
        return this.$message.error('请刷卡');
      }
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.laseDialogVisible = true;
        }
      });
    },
    laseDialog() {
      this.laseDialogVisible = false;
      let form = {};
      form.rfid_id = this.prop.rfid_id;
      form.user_id = this.prop.user_id;
      form.stff_id = this.prop.stff_id;
      form.stff_name = this.prop.stff_name;
      form.user_rfid_no = this.prop.user_rfid_no;
      form.rfid_lase_phr = this.form.rfid_lase_phr;
      form.rfid_patch_lase = this.form.rfid_patch_lase;
      post(rfidAPI.edit_lase, form)
        .then(res => {
          if (res.data.code !== 0) {
            return this.$message.error(res.data.msg);
          }
          this.$message.success(res.data.msg);
          this.$refs.form.resetFields();
          this.$emit('empty');
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    lasePhrKeyup() {
      this.$refs.lasepatchRef.focus();
    },
    keepNum99(val){
      let keepTNum = Number(helper.keepTNum(val));
      if(keepTNum > 99){
        this.$message.warning("输入范围0～99超出最大限度");
        return 99;
      }else{
        return keepTNum;
      }
    }
  },
  mounted() {
    this.$EventBus.$on('lase', () => {
      this.form.rfid_lase_phr = '';
      this.form.rfid_patch_lase = '';
    });
    this.$EventBus.$on('laseEmit', res => {
      this.form.rfid_lase_phr = res.rfid_lase_phr;
      this.form.rfid_patch_lase = res.rfid_patch_lase;
      if (!this.$refs.laseRef) {
        return;
      } else {
        this.$refs.laseRef.focus();
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.laseForm .el-form-item {
  margin-bottom: 20px;
}
.laseForm {
  width: 90%;
  margin: 0 auto;
  min-width: 500px;
}
::v-deep .laseForm .el-form-item__label {
  font-size: $font-max;
}
::v-deep .laseForm .el-input {
  height: 60px;
  font-size: $font-max;
}
::v-deep .laseForm .el-input input {
  height: 60px;
}
::v-deep .el-form-item__content {
  margin-bottom: 10px;
}
::v-deep .el-form-item__error {
  font-size: 18px;
  margin-bottom: 2px;
}
</style>
